<template>
    <div>
        <div class="card p-2 pl-3 pr-3 mb-3"
              v-if="licenseClientConnected.length > 0">
            <div>
                <strong class="text-primary">SOFTWARE LICENSE INFOMATION</strong>
            </div>

            <div class="row">
                <div class="col-12 col-sm-4 mt-0" 
                     :key="clientItem.userId" v-for="clientItem in licenseClientConnected">
                    <el-card class="socket-client-card box-card p-0"
                             :body-style="{ padding: '10px' }">
                        <div class="mb-2" v-if="isNumeric(clientItem.licenseUsedType)">
                            <div v-if="clientItem.licenseUsedType === 2">
                                <img class="mr-4" src="/img/OctaneRender.svg" alt="OctaneRender" style="height: 40px; ">
                                <img src="/img/RedshiftRender.svg" alt="RedshiftRender" style="height: 40px; ">
                            </div>
                            <div v-else>
                                <img :src="`/img/${clientItem.licenseUsedType === 0 ? 'RedshiftRender' : 'OctaneRender'}.svg`"
                                     :alt="`${clientItem.licenseUsedType === 0 ? 'RedshiftRender' : 'OctaneRender'}`"
                                     style="height: 40px;">
                            </div>
                        </div>
                        <div class="mb-2" v-else>
                            <div>
                                <img v-if="clientItem.licenseUsedType.octane"
                                     class="mr-4"
                                     src="/img/OctaneRender.svg" alt="OctaneRender" style="height: 30px; ">
                                <img v-if="clientItem.licenseUsedType.redshift"
                                     class="mr-4"
                                     src="/img/RedshiftRender.svg" alt="RedshiftRender" style="height: 30px; ">
                                <span v-if="clientItem.licenseUsedType.redshift"
                                      class="mr-4 text-nowrap"
                                      style="font-size: 1.4rem; font-weight: 600;">Cinema 4D</span>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="flex-fill d-flex">
                                <div class="w-50">
                                    <div><strong>Machine ID:</strong> {{clientItem.vmMachineId}}</div>
                                    <div><strong>Computer:</strong> {{clientItem.computerName}}</div>
                                    <div><strong>IP Address:</strong> {{clientItem.ipAddress20}}</div>
                                </div>
                                <div class="w-50">
                                    <div><strong>Public IP:</strong> {{clientItem.remoteAddress}}</div>
                                    <div><strong>MAC:</strong> {{clientItem.macAddress}}</div>
                                    <div><strong>Connected:</strong> {{clientItem.connected}}</div>
                                </div>
                                <!--<div><strong>Client ID:</strong> {{clientItem.userId}}</div>-->
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import usersApi from "@/api/common/users";
    export default {
        data() {
            return {
                isLoading: false,
                licenseClientConnected: [],
                intervalLoad: null,

            };
        },
        mounted() {
            this.getAllLicenseConnected();
            this.intervalLoad = setInterval(() => {
                this.getAllLicenseConnected();
            }, 10000);
        },
        beforeDestroy() {
            clearInterval(this.intervalLoad);
        },
        methods: {
            isNumeric(str) {
                if (typeof str != "string") return false // we only process strings!  
                return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
            },
            getAllLicenseConnected() {
                usersApi
                    .getAllLicenseConnected()
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.$set(this, "licenseClientConnected", response.data.data);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        }
    }
</script>